<template>
  <div>
    <HeaderComponent />
    <main class="container my-5">
      <h1>Welcome to our Vue.js + Bootstrap Web App</h1>
      <p>This is the main content area of the web page.</p>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
};
</script>

<style>
body, html {
  height: 100%;
  margin: 0;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}
</style>
