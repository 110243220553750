<template>
  <footer class="bg-light text-center text-lg-start">
    <div class="container p-4">
      <p>&copy; 2024 VueApp. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped>
footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>
